<template>
  <div v-if="selectedHorario">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-card>
        <b-row>
          <!-- TITULO Y SUBTITULO -->
          <b-col cols="6">
            <h4 class="mb-0 mt-1">
              {{getCurso.nombre}} {{getCurso.letra}}
            </h4>
            <p class="text-muted">
              <!-- Ingresa las horas de tus asignaturas. -->
              <!-- <br>
              A la derecha en "Cursos", podrás navegar por los horarios de los
              cursos que seleccionaste previamente. -->
            </p>
          </b-col>
          <b-col cols="6" class="mb-1">
            <b-alert
              show
              variant="primary"
              class="pt-25 pb-25 pl-50 pr-25 mt-0 mb-0 text-center"
            >
              <div class="alert-body text-center mt-25">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                Para ingresar un nuevo bloque, haz click en la hora de un día.
              </div>
            </b-alert>
          </b-col>
          <b-col cols="12">
            <horarios-form
              :id_curso_selected="selectedHorario"
              :id_tipo_ensenanza.sync="curso_selected.id_tipo_ensenanza"
              @processForm="editar"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// ETIQUETAS
import { BOverlay, BCard, BCol, BRow, BAlert } from 'bootstrap-vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// COMPONENTES HIJOS
import horariosForm from '../Mantenedores/Horarios/components/HorariosForm.vue'

export default {
  components: {
    // ETIQUETAS
    BOverlay,
    BCard,
    BCol,
    BRow,
    BAlert,

    // COMPONENTES HIJOS
    horariosForm,
  },
  data() {
    return {
      spinner: false,
      curso_selected: {},
    }
  },
  computed: {
    ...mapState('horarios', ['selectedHorario']),
    ...mapGetters({
      getCurso: 'cursos/getCurso',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      user: 'auth/user',
    }),
  },
  mounted() {
    this.cargaAsignatura(this.selectedHorario)
    this.cargarCursosHorario(this.selectedHorario)
  },
  methods: {
    ...mapActions({
      updateHorario: 'horarios/updateHorario',
      fetchCurso: 'cursos/fetchCurso',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
    }),
    cargaAsignatura(curso) {
      this.fetchCurso(curso).then(() => {})
    },
    cargarCursosHorario(curso) {
      this.cargando_cursos = true
      this.fetchCursosPersonaRol(this.user.id_persona_rol).then(() => {
        this.curso_selected = this.getCursosPersonaRol.find(c => c.id === curso)
      })
    },
    editar(horario) {
      this.spinner = true
      this.updateHorario(horario).then(() => {
        const errorhorarios = store.state.horarios
        const errorMessage = errorhorarios.errorMessage.errors
        if (!errorhorarios.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Horario editado 👍',
              text: `El horario "${horario.nombre}" fue editado con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'horarios',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorhorarios.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
